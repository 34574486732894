@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/breakpoints';

.FinishedThemeBgList {
  list-style: none;

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  width: 100%;

  margin: 0;
  padding: 0;

  pointer-events: none;

  &:after {
    content: '';

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    width: 100%;

    background: url("./assets/bg.png") right center no-repeat;
    background-size: auto 100%;
  }
}

.FinishedThemeBgItem {
  position: absolute;
  top: 50%;
  right: 0;
  width: 100%;

  margin: 0;

  background: url("./assets/grid1.png") right center no-repeat;
  background-size: auto 100%;

  color: #041A30;

  &:nth-child(1) {
    margin-top: vh(-342px);

    height: vh(361px * 0.79);

    background-image: url("./assets/grid1.png");
  }
  &:nth-child(2) {
    margin-top: vh(-222px);

    height: vh(216px * 0.79);

    background-image: url("./assets/grid2.png");
  }
  &:nth-child(3) {
    margin-top: vh(-80px);

    height: vh(141px * 0.79);

    background-image: url("./assets/grid3.png");
  }
  &:nth-child(4) {
    margin-top: vh(-45px);

    height: vh(203px * 0.79);

    background-image: url("./assets/grid4.png");
  }
  &:nth-child(5) {
    margin-top: vh(-40px);

    height: vh(318px * 0.79);

    background-image: url("./assets/grid5.png");
  }
  &:nth-child(6) {
    margin-top: vh(-35px);

    height: vh(422px * 0.79);

    background-image: url("./assets/grid6.png");
  }
}

.FinishedThemeTextList {
  list-style: none;

  position: absolute;
  top: 50%;
  right: 0;

  margin: 0;
  margin-top: vh(-40px);
  padding: 0;

  pointer-events: none;
}

$line-text-width: 250px;

.FinishedThemeTextItem {
  box-sizing: content-box;
  position: absolute;
  top: 0;
  right: vh(-$line-text-width - 10px);

  width: vh($line-text-width);
  margin: 0;
  margin-top: vh(-20px);
  padding-right: vh($line-text-width);

  font-size: vh(20px);
  font-weight: 600;
  line-height: 1;
  text-align: center;

  span {
    overflow: hidden;
    display: inline-block;

    max-width: vh($line-text-width - 10px);

    padding-right: vh(60px);

    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:nth-child(1) {
    transform: rotate(48.04deg);
  }
  &:nth-child(2) {
    transform: rotate(19.88deg);
  }
  &:nth-child(3) {
    transform: rotate(-5.04deg);
  }
  &:nth-child(4) {
    transform: rotate(-23.95deg);
  }
  &:nth-child(5) {
    transform: rotate(-44.49deg);
  }
  &:nth-child(6) {
    transform: rotate(-65.74deg);
  }
}

.Themes {
  width: 100%;
  height: 100%;
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: self-start;

  padding: vmin(30px) vmin(150px) 5vmin;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.Button {
  margin-bottom: vmin(30px) !important;
}

@include orientation-and-max-width(portrait, 1200px) {
  .FinishedThemeBgList,
  .FinishedThemeTextList {
    display: none;
  }

  .Themes {
    align-items: center;
  }
}
