@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/breakpoints';

.Text {
  font-size: $typography-accent-font-size;
  text-align: center;
  margin: auto;
}

.Container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: vmin(-40px);
  padding: vmin(40px) 0 0;

  h2, h3, h4 {
    margin-bottom: vmin(6px);
    padding-top: 0;
  }
}

.Grid {
  overflow: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
