@import 'src/styles/variables';
@import 'src/styles/breakpoints';
@import 'src/styles/mixins';

.ButtonWrapper {
  margin-top: auto;
}

.Timer {
  padding: 0 vmin(30px);
}

.ButtonChecked {
  background-color: $color-blue;
  border-radius: vmin(10px);
}

.Icon {
  height: 100% !important;
  width: vmin(50px) !important;
  color: $color-white;
}

.Grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 80%;
  justify-items: start;
  text-align: left;
}

.QuestionTitle {
  position: absolute;
  top: vmin(12px);
  left: 50%;

  transform: translateX(-50%);

  font-size: vmin(32px);
  line-height: 1;
  font-weight: 500;
  letter-spacing: vmin(5.12px);
}

.Text {
  position: relative;

  width: 80%;

  //margin-top: vmin(50px);
  padding: vmin(30px);

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  white-space: pre-wrap;
  text-align: left !important;
  font-weight: 500;
  font-size: vmin(32px);
  line-height: 1.25;
  text-shadow: 0 0 20px 0 rgba(255, 241, 115, 0.60);
}

.OnlyImage {
  background-color: #053A6C;
  border: vmin(5px) solid #E2A41D;
  border-radius: vmin(16px);

  font-size: 5.5vmin !important;
  text-align: center !important;
}

.OnlyImage .QuestionTitle {
  font-size: vmin(16px);
}

.ArrowContainer {
  width: 100%;
}

.IconArrow {
  height: vmin(20px) !important;
  color: #041A30;
}

.BackButton,
.ForwardButton {
  position: absolute !important;
  top: vmin(55px) !important;;
  right: vmin(20px) !important;

  width: auto !important;
  min-width: 0 !important;

  @include breakpoint-max-width(1200px) {
    top: vmin(70px) !important;
  }
}

.BackButton {
  right: auto !important;
  left: vmin(20px) !important;

  padding: vmin(12px) vmin(20px) vmin(12px) vmin(10px) !important;

  @include breakpoint-max-width(1200px) {
    padding-right: vmin(10px) !important;
  }
}

.ForwardButton {
  padding: vmin(12px) vmin(10px) vmin(12px) vmin(20px) !important;

  @include breakpoint-max-width(1200px) {
    padding-left: vmin(10px) !important;
  }
}

.DirectionButtonText {
  @include breakpoint-max-width(1200px) {
    display: none;
  }
}

.Title {
  font-size: vmin(45px);
  flex: 1;
  display: flex;
  align-items: center;
}

.ViewerTimer {
  padding-bottom: 1vmin !important;
}
