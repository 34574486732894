@import 'src/styles/mixins';

.Wrapper {
  h1 {
    padding-top: 0 !important;
  }
}

.Container {
  gap: vmin(25px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.Footer {
  position: absolute;
  left: 50%;
  bottom: vmin(115px);

  transform: translateX(-50%);
}

.Label {
  padding-bottom: vmin(5px);

  font-size: vmin(16px);
  font-weight: 500;
  color: #A89670;
}

.Input {
  width: vmin(346px);

  font-size: vmin(20px);
  font-weight: 600;
  line-height: 1;
}

.Button {
  margin-top: vmin(70px) !important;
}
