@import 'src/styles/variables';
@import 'src/styles/breakpoints';
@import 'src/styles/mixins';

.Tabs {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: vmin(60px);
}

.ParticipantsCount {
  display: flex;
  align-items: center;
  position: absolute;
  right: vmin(10px);
  top: vmin(10px);
  gap: vmin(5px);
  transition: opacity 2s;
  opacity: 1;
  font-size: vmin(15px);
  cursor: pointer;

  p {
    margin: auto;
  }

  svg {
    width: calc(2vmin + 1rem);
    height: calc(2vmin + 1rem);
  }
}

.LogoutGame {
  position: absolute;
  top: vmin(10px);
  left: vmin(10px);
  cursor: pointer;

  svg {
    width: calc(2vmin + 1rem);
    height: calc(2vmin + 1rem);
  }
}

.Container {
  height: 100%;
  padding: 0 vmin(10px) vmin(75px);
  text-align: center;
}

.TextModal {
  position: absolute;
  top: 50%;
  left: 45%;
  min-width: 100vmin;
  transform: translate(-40%, -50%);
  background-color: #041A30;
  border-radius: vmin(20px) !important;
  padding: vmin(20px);

  h2 {
    margin-bottom: vmin(10px);

    text-align: center;
  }

  :global .MuiButton-root + .MuiButton-root {
    margin-left: vmin(10px);
  }
}

.Buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: vmin(15px);
}

.Modal {
  background-color: #041A30;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: vmin(20px) vmin(30px);
  border-radius: vmin(20px) !important;
}

.Line {
  border-bottom: 1px solid #d0cdcd;
  width: 100%;
  height: vmin(5px);
}

.Disconnected {
  //color: $color-red;
}

.DisconnectedTeam {
  padding: vmin(5px);
}

.HeaderContainer {
  width: 100%;
  height: vmin(40px);
  position: relative;
  margin-bottom: 1vmin;
  z-index: 1;

  h5 {
    margin: 0 !important;
    padding: 2vmin 0;
  }
}
