@import 'src/styles/mixins';

.Container {
  margin: 0 vmin(10px);
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: vmin(20px);

  padding-bottom: vmin(100px);
}

.FlexContainer {
  display: flex;
  flex-direction: column;
  gap: vmin(15px);
}


.Footer {
  position: absolute;
  left: 50%;
  bottom: vmin(115px);

  transform: translateX(-50%);
}

.Label {
  padding-bottom: vmin(5px);

  font-size: vmin(16px);
  font-weight: 500;
  color: #A89670;
}

.Input {
  width: vmin(346px);

  font-size: vmin(20px);
  font-weight: 600;
  line-height: 1;
}

.Button {
  margin-top: vmin(70px) !important;
}

//h1 {
//  padding: vmin(30px) 0 vmin(50px);
//}
