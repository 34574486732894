@import 'src/styles/mixins';
@import 'src/styles/breakpoints';

.Container {
  gap: vmin(5px);

  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: vmin(20px) 0;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  h3 {
    margin-bottom: vmin(50px);
  }
}

.Button {
  width: vmin(360px);

  margin: 0 auto;
}

.Key {
  position: absolute;
  bottom: vmin(70px + 70px);
  left: 50%;

  transform: translateX(-50%);

  min-width: vmin(346px);

  padding-bottom: vmin(5px);

  font-size: vmin(20px);
  font-weight: 600;
  line-height: 1;

  border-bottom: vmin(5px) solid #E2A41D;

  &Title {
    padding-bottom: vmin(5px);

    font-weight: 500;
    font-size: vmin(16px);
    color: #A89670;
  }
}

.Wrapper {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: vmin(30px);
}
