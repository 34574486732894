@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/breakpoints';

.Wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: vmin(45px);
}

.Container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  svg {
    z-index: 15;
  }
}

.Route {
  position: absolute;
  top: vmin(5px);
  right: vmin(98px);
  z-index: 15;
}

.Button {
  width: vmin(150px);
  min-width: 0 !important;
  padding: vmin(12px) vmin(20px) vmin(12px) vmin(10px) !important;
  margin: 0 0 vmin(10px) !important;
  z-index: 16;
}

.ArrowContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 10%;
}

.IconArrow {
  height: vmin(20px) !important;
  color: #041A30;
}

.BackButton,
.ForwardButton {
  position: absolute !important;
  top: 0 !important;;
  right: vmin(40px) !important;

  width: auto !important;
  min-width: 0 !important;
}

.BackButton {
  right: auto !important;
  left: vmin(40px) !important;

  padding: vmin(12px) vmin(20px) vmin(12px) vmin(10px) !important;

  @include breakpoint-max-width(1200px) {
    padding-right: vmin(10px) !important;
  }
}

.ForwardButton {
  padding: vmin(12px) vmin(10px) vmin(12px) vmin(20px) !important;

  @include breakpoint-max-width(1200px) {
    padding-left: vmin(10px) !important;
  }
}

.DirectionButtonText {
  @include breakpoint-max-width(1200px) {
    display: none;
  }
}
