@import 'src/styles/mixins';
@import 'src/styles/breakpoints';

.DisplayNone {
  display: none;
}

.Container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: vmin(-45px);
  padding-top: vmin(45px);

  h2, h3, h4 {
    margin-bottom: vmin(5px);
  }

  h5 {
    position: absolute;
    top: vmin(-10px);
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.Grid {
  height: auto;
  flex: 1;
  gap: vmin(15px);
  overflow: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
