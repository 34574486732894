@import 'src/styles/mixins';

.Grid {
  //padding: 80% 0 0 0;
}

.Wrapper {
  height: 100%;
  padding: 10vmin 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  p {
    margin: vmin(10px) 0 vmin(30px);
  }
}

.ContentContainer {
  margin: auto;
}
