@import 'src/styles/variables';
@import 'src/styles/breakpoints';
@import 'src/styles/mixins';

.Media {
  display: flex;
  align-items: center;
  margin: 1vmin 0;

  audio {
    height: revert !important;
  }

  img {
    max-height: vmin(230px);
    margin: auto;
  }
}

.Image {
  max-width: vmin(600px);
  max-height: vmin(400px);
  margin-bottom: vmin(20px);

  width: auto !important;
  height: 100%;

  @include tablet {
    max-height: initial !important;
    width: 100% !important;
    height: auto !important;
  }
}

.FullImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: initial !important;
  max-width: initial !important;
  width: 100% !important;
  height: auto !important;
  z-index: 10;
}

.HighImage {
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100% !important;
  max-width: vmin(600px) !important;
  height: 100% !important;
  width: auto !important;
  margin: 0 !important;

  @include small-tablet {
    max-width: initial !important;
  }
}