@import 'src/styles/variables';
@import 'src/styles/mixins';

.Placeholder {
  color: $color-placeholder;
}

.Button {
  margin-top: auto !important;
  display: flex;
  flex-direction: column;
  gap: 3vmin;
}
