@import 'src/styles/variables';
@import 'src/styles/breakpoints';
@import 'src/styles/mixins';

.LogoutGame {
  position: absolute;
  top: vmin(10px);
  left: vmin(10px);
  z-index: 10;

  svg {
    width: calc(2vmin + 1rem);
    height: calc(2vmin + 1rem);
  }
}

.ParticipantsCount {
  display: flex;
  align-items: center;
  position: absolute;
  right: vmin(10px);
  top: vmin(10px);
  gap: vmin(5px);
  transition: opacity 2s;
  opacity: 1;
  font-size: vmin(15px);

  p {
    margin: auto;
  }

  svg {
    width: calc(2vmin + 1rem);
    height: calc(2vmin + 1rem);
  }
}

.Container {
  overflow: auto;
  height: 100%;
  padding: 0 vmin(10px) vmin(70px);
  text-align: center;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &ThemeSelecting {
    padding-bottom: 0;
  }
}

.HeaderContainer {
  width: 100%;
  height: vmin(40px);
  position: relative;
  margin-bottom: 1vmin;
  z-index: 1;

  h5 {
    margin: 0 !important;
    padding: 2vmin 0;
  }
}

.TextModal {
  position: absolute;
  top: 50%;
  left: 45%;
  min-width: 100vmin;
  transform: translate(-40%, -50%);
  background-color: #041A30;
  border-radius: vmin(20px) !important;
  padding: vmin(20px);

  h2 {
    margin-bottom: vmin(10px);

    text-align: center;
  }

  :global .MuiButton-root + .MuiButton-root {
    margin-left: vmin(10px);
  }
}

.TeamName {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: vmin(25px) 25% vmin(5px);
  font-weight: 500;
  font-size: vmin(16px);
  color: #fff;
  //background-color: #fff;
}

