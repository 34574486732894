@import "src/styles/mixins";

.Box {
  width: 100%;
}

.ListItemButton {
  border-bottom: 1px solid cornflowerblue;
}

.Item {
  transition: height 0.7s;
  overflow: hidden;
  position: relative;
}

.ItemBlock {
  min-height: vmin(300px);
  height: 100%;
}

.ItemNone {
  height: 0;
}

.Text {
  color: #fff;
}
