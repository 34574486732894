@import 'src/styles/variables';
@import "src/styles/mixins";

.Text {
  font-size: vmin(45px);
  margin: auto;
  text-align: left;
}

.Timer {
  padding: 0 vmin(30px);
}

