@import "styles/mixins";

.TableCell {
  background-color: #B4BDC6;

  font-size: vmin(20px);
  font-weight: 600;
  color: #041A30;
  line-height: 1;
  text-align: center !important;

  svg {
    color: #041A30;
  }

  input {
    width: 10vmin !important;
  }

  button {
    margin: 0 vmin(10px);
    padding: vmin(8px) !important;
  }
}

.TableRow:nth-child(2n) .TableCell {
  background-color: #CDD3D8;
}

.TableCellEdit {
  width: vmin(80px);
}

.CellButtonWrapper {
  display: flex;
  align-items: center;
}

.IconWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
