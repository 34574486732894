@import "styles/mixins";

.Root {
  display: inline-block;
  margin: 0 vmin(30px);

  font-size: vmin(32px);
  font-weight: 500;
  line-height: 1.25;

  @include breakpoint-max-width(650px) {
    font-size: vmin(28px);
  }
}
