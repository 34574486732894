@import 'src/styles/breakpoints';
@import 'src/styles/mixins';

.ParticipantsContainer {
  overflow: scroll;
  gap: vmin(20px);
  text-align: center;
  flex: 1;
  margin: vmin(20px) 0;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  svg {
    width: calc(2vmin + 1rem);
    height: calc(2vmin + 1rem);
  }
}

.ParticipantsContainerViewer {
  height: vmin(200px);

  @include small-tablet {
    flex-direction: row !important;
    justify-content: space-around;
    height: auto;
  }
}

.SmallAmount {
  @include small-tablet {
    flex-direction: column !important;
  }
}

.Item {
  display: flex;
  justify-content: flex-start;
  padding: vmin(10px);
  flex: 0 0 33.3333%;

  & > p {
    text-align: left;
    word-break: keep-all;

    padding-left: vmin(10px);
  }
}

h3 {
  padding-top: vmin(60px);
}

.QRCode {
  width: vmin(256px + 40px);

  margin: 0 auto vmin(20px);
  padding: vmin(20px);

  background-color: #fff;

  & > svg {
    width: vmin(256px);
    height: vmin(256px);
  }
}

.Wrapper {
  width: 100%;
  margin: 0 auto;
  overflow: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
