@import "styles/mixins";

.Participants {
  @include clearfix;

  margin: vmin(50px) vmin(150px) vmin(20px);
}

.Participant {
  float: left;
  box-sizing: border-box;

  width: calc(50% - #{vmin(15px)});

  margin-bottom: vmin(20px);
  padding: vmin(16px) vmin(36px);

  background: #B4BDC6;
  border-radius: vmin(30px);

  &:nth-child(2n) {
    margin-left: vmin(25px);
  }
  @include breakpoint-max-width(600px) {
    float: none;
    width: 100%;

    margin-left: 0 !important;
  }

  p {
    margin-left: vmin(25px);

    font-weight: 600;
    font-size: vmin(28px);
    color: #041A30;
  }
}
