$PI: 3.14159265358979;
$rpcc-size: 32vmin;
$rpcc-progress-size: $rpcc-size / 8;
$rpcc-ring-radius: ($rpcc-size / 2) - ($rpcc-progress-size / 2);
$rpcc-ring-circum: $PI * $rpcc-ring-radius * 2;
$rpcc-play-button-size: $rpcc-size - ($rpcc-progress-size * 2);
$rpcc-play-button-icon-size: $rpcc-play-button-size * 0.15;
$rpcc-play-icon-color: #E2A41D;

//$rpcc-button-color: #f00;
$rpcc-ring-color: #041A30;
//$rpcc-ring-duration-color: #0c243b;
$rpcc-ring-duration-color: #041A30;
$rpcc-ring-played-color: #E2A41D;

.rpcc-player-inner {
  display: flex;
  width: $rpcc-size;
  height: $rpcc-size;
  position: relative;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';

    position: absolute;
    bottom: 0;
    left: 50%;

    width: $rpcc-progress-size;
    height: $rpcc-progress-size;

    margin-top: -($rpcc-progress-size / 2);
    margin-left: -($rpcc-progress-size / 2);

    background-color: $rpcc-ring-played-color;
    border-radius: 50%;

    z-index: 1;

    pointer-events: none;
  }
}

.rpcc-ring-container {
  position: absolute;
  top: 0;
  right: 0;

  width: $rpcc-size;
  height: $rpcc-size;

  transform: rotate(90deg);

  z-index: auto !important;
}

.rpcc-ring-end {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  transform: rotate(calc(360deg * var(--rpcc-progress-played)));

  &:after {
    content: '';

    position: absolute;
    bottom: 0;
    left: 50%;

    width: $rpcc-progress-size;
    height: $rpcc-progress-size;

    margin-top: -($rpcc-progress-size / 2);
    margin-left: -($rpcc-progress-size / 2);

    background-color: $rpcc-ring-played-color;
    border-radius: 50%;

    z-index: 1;

    pointer-events: none;
  }
}

.rpcc-ring {
  cx: $rpcc-size / 2;
  cy: $rpcc-size / 2;
  fill: none;
  stroke-width: $rpcc-progress-size;
  r: $rpcc-ring-radius;
  stroke-dasharray: $rpcc-ring-circum;
  shape-rendering: optimizeQuality;
  stroke-dashoffset: 0;
}

.rpcc-ring__duration {
  stroke: $rpcc-ring-duration-color;
}

.rpcc-ring__loaded {
  stroke: $rpcc-ring-color;
  stroke-dashoffset: calc($rpcc-ring-circum * (1 - var(--rpcc-progress-loaded)));
}

.rpcc-ring__played {
  stroke: $rpcc-ring-played-color;
  stroke-dashoffset: calc($rpcc-ring-circum * (1 - var(--rpcc-progress-played)));
}

.rpcc-play-button {
  display: block;
  position: relative;

  width: $rpcc-play-button-size;
  height: $rpcc-play-button-size;

  margin: 0 !important;
  padding: 0;

  border: none;
  border-radius: 50%;
  background: radial-gradient(49.95% 49.95% at 50% 50%, #1D92FE 0%, #0A4379 81.91%, #002E5B 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

  cursor: pointer;

  &:before {
    content: '';

    $rpcc-logo-offset: $rpcc-progress-size * 0.3;

    position: absolute;
    top: $rpcc-logo-offset;
    left: $rpcc-logo-offset;
    right: $rpcc-logo-offset;
    bottom: $rpcc-logo-offset;

    background: url("./assets/audio-logo.png") center center no-repeat;
    background-size: 90%;
  }
}

.rpcc-play-button:hover {
  background: #003365;
}

.rpcc-play-button:active {
  opacity: 0.9;
}

.rpcc-play-icon {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 0;
  height: $rpcc-play-button-icon-size;
  margin-left: $rpcc-play-button-size * 0.04166666667;
  border-color: transparent transparent transparent $rpcc-play-icon-color;
  transition: 100ms all ease;
  border-style: solid;
  border-width: ($rpcc-play-button-icon-size / 2) 0 ($rpcc-play-button-icon-size / 2) $rpcc-play-button-icon-size;
  //box-shadow: 0 0 $rpcc-size rgb(255, 241, 115);
  filter: drop-shadow(0 0 ($rpcc-play-button-size / 20) rgb(255, 241, 115));
}

.rpcc-play-icon.pause {
  margin-left: 0;
  border-style: double;
  border-width: 0 0 0 $rpcc-play-button-icon-size;
}
