@import 'src/styles/mixins';
@import 'src/styles/breakpoints';

.ListItemContainer {
  width: 100%;
  margin-bottom: vmin(28px);
  margin-top: vmin(30px);
  display: flex;
  flex-direction: column;
  gap: vmin(10px);
  overflow: scroll;
  flex: 1;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.Text {
  margin: auto;
  color: #fff;
}

.Box {
  width: 100%;
}
