@import 'src/styles/variables';
@import 'src/styles/breakpoints';
@import 'src/styles/mixins';

.Media {
  height: 100% !important;
  width: 100% !important;
  display: flex;
  align-items: center;
  flex: 1;

  audio {
    height: revert !important;
  }
}

.QuestionTitle {
  position: absolute;
  top: vmin(12px);
  left: 50%;

  transform: translateX(-50%);

  font-size: vmin(32px);
  line-height: 1;
  font-weight: 500;
  letter-spacing: vmin(5.12px);
  text-shadow: 0 0 vmin(20px) rgba(255, 241, 115, 0.60);
  background: linear-gradient(#FDE5BA, #FEE7BD, #693E23, #DC8A16);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Text {
  position: relative;

  width: 80%;

  //margin-top: vmin(50px);
  padding: vmin(30px);

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  white-space: pre-wrap;
  text-align: left !important;
  font-weight: 500;
  font-size: vmin(32px);
  line-height: 1.25;
}

.OnlyImage {
  background-color: #053A6C;
  border: vmin(5px) solid #E2A41D;
  border-radius: vmin(16px);

  font-size: 5.5vmin !important;
  text-align: center !important;
}

.OnlyImage .QuestionTitle {
  font-size: vmin(16px);
}

.Title {
  font-size: vmin(45px);
  flex: 1;
  display: flex;
  align-items: center;
}

.Timer {
  padding: 0 vmin(30px);
}

.Image {
  max-width: vmin(600px);
  max-height: vmin(300px);
  margin-bottom: vmin(20px);

  width: auto !important;
  height: 100%;

  @include tablet {
    max-height: initial !important;
    width: 100% !important;
    height: auto !important;
  }
}

.Grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 80%;
  justify-items: start;
  text-align: left;
}

.ButtonWrapper {
  margin-top: auto;
}
