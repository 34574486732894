@import 'src/styles/mixins';
@import 'src/styles/breakpoints';

.Wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: vmin(48px);
  overflow-y: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.Container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: vmin(10px) 0;
  padding: vmin(10px);
  border: 1px dashed;

  svg {
    z-index: 9;
  }

  h4 {
    padding: vmin(10px) 0;
    border-bottom: 1px solid black;
    margin-bottom: vmin(10px);
  }

  p {
    text-align: left;
  }
}

.Route {
  position: absolute;
  top: vmin(5px);
  right: vmin(98px);
}

.Button {
  width: vmin(150px);
  min-width: 0 !important;
  padding: vmin(12px) vmin(20px) vmin(12px) vmin(10px) !important;
  z-index: 16;
}

.Bold {
  font-weight: bold;
  white-space: pre-wrap;
}

.Text {
  margin-bottom: vmin(5px);
}
