body {
  .MuiButtonBase-root {
    padding: vmin(12px) vmin(30px);

    color: #fff;
  }
  .MuiAutocomplete-endAdornment {
    top: calc(50% - 20px);
  }
  .MuiButtonBase-root.MuiAutocomplete-clearIndicator {
    padding: 4px;
  }
  .MuiFormLabel-root,
  .MuiInputLabel-root {
    &,
    &.Mui-focused {
      color: #fff;
    }
  }
  .MuiInputBase-root {
    &,
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #fff;

        color: #fff;
      }
    }
  }
  .MuiTable-root .MuiInputBase-root {
    &,
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #041A30;

        color: #041A30;
      }
    }
  }
  .MuiInputBase-input {
    color: #fff;
    text-align: center;
  }
  .MuiTable-root .MuiInputBase-input {
    color: #041A30;
  }
  .MuiTabs-indicator {
    display: none !important;
  }
  .MuiTabs-root {
    min-height: 0;

    margin: 0 0 vmin(5px);
  }
  .MuiTab-root {
    max-width: none;
    min-height: 0;

    margin: 0 vmin(5px);

    background-color: #041A30;
    border: vmin(5px) solid #E2A41D;
    border-radius: vmin(36px);

    font-weight: 500;
    font-size: vmin(36px);
    color: #E2A41D;
    line-height: 1.15;

    &.Mui-selected {
      background-color: #E2A41D;

      color: #041A30;
    }
  }
}
