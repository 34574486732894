@import 'src/styles/mixins';

.root {
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  width: 90vw;
  max-width: 800px;

  padding: vmin(30px);

  background-color: #041A30 !important;
}

.submitBtn {
  width: 100%;
}
