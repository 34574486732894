@import 'src/styles/variables';
@import 'src/styles/breakpoints';
@import 'src/styles/mixins';

.Titles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 0 vmin(80px);
}

.Animation {
  transition: opacity 0.2s ease-in-out;
}

.Image {
  max-width: vmin(600px);
  max-height: vmin(400px);
  margin-bottom: vmin(20px);

  width: auto !important;
  height: 100%;

  @include tablet {
    max-height: initial !important;
    width: 100% !important;
    height: auto !important;
  }
}

.Media {
  display: flex;
  align-items: center;

  audio {
    height: revert !important;
  }

  img {
    max-height: vmin(230px);
    margin: auto;
  }
}

.Video {
  flex: 2;

  width: vmin(640px) !important;
  height: vmin(360px) !important;

  & > div {
    width: 100%;
    margin: 0 auto;

    @include tablet {
      height: auto !important;
    }
  }
}

.Block {
  opacity: 1;
}

.None {
  opacity: 0;
}

.Option {
  display: flex;
  align-items: center;
  margin-top: vmin(10px);

  p {
    text-align: left !important;
  }
}

.Options {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto auto vmin(10px);
  z-index: 1;

  p {
    text-align: center;
  }
}

.ButtonChecked {
  padding: 0 !important;
  border-radius: vmin(36px);
  cursor: pointer;
  border: none;
  min-width: 0 !important;
  width: vmin(56px);
  height: vmin(56px);

  svg {
    width: vmin(23px) !important;
    height: vmin(23px) !important;

    color: #041A30;
  }

  &:hover {
    background-position: 100% 0;
    color: white;
    transition: all 0.4s ease-in-out;
  }

  p {
    color: white;
    padding: vmin(5px) vmin(10px);
  }
}

.Icon {
  height: 100% !important;
  width: vmin(50px) !important;
  color: $color-white;
}

.Grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  justify-items: start;
  text-align: left;
}

.TextFieldWrapper {
  display: flex;
  gap: vmin(10px);
}

.TextField {
  border: vmin(5px) solid #E2A41D !important;
  border-radius: vmin(36px) !important;

  label,
  fieldset {
    display: none;
  }
  input {
    padding: vmin(14px) vmin(14px);
    background-color: #041A30 !important;
    font-size: vmin(16px) !important;
    font-weight: 500;
    color: #A89670 !important;
    line-height: 1;

    border-radius: vmin(36px) !important;

    //border: vmin(5px) solid #E2A41D;
    //border-radius: vmin(36px);

    &::placeholder {
      color: #A89670;
      opacity: 1;
    }
  }
  input:-webkit-autofill {
    &,
    &:focus {
      background-color: #041A30 !important;
    }
  }
}

.QuestionTitle {
  position: absolute;
  top: vmin(12px);
  left: 50%;

  transform: translateX(-50%);

  font-size: vmin(32px);
  line-height: 1;
  font-weight: 500;
  letter-spacing: vmin(5.12px);
}

.Text {
  position: relative;

  width: 80%;

  //margin-top: vmin(50px);
  padding: vmin(30px);

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  white-space: pre-wrap;
  text-align: left;
  font-weight: 500;
  font-size: vmin(32px);
  line-height: 1.25;
}

.OnlyImage {
  background-color: #053A6C;
  border: vmin(5px) solid #E2A41D;
  border-radius: vmin(16px);

  font-size: 5.5vmin !important;
  text-align: center !important;
}

.OnlyImage .QuestionTitle {
  font-size: vmin(16px);
}

.AnswerSection {
  //margin-top: auto;
}
