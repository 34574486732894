@import 'src/styles/mixins';
@import 'src/styles/breakpoints';

.Wrapper {
  height: 100%;
  padding: vmin(10px);
  max-width: 60%;
  margin: 0 auto;

  @include tablet {
    max-width: 100%;
  }
}

.Container {
  h2 {
    margin-bottom: vmin(80px);
  }
}
