@import 'src/styles/mixins';
@import 'src/styles/breakpoints';

.Slide {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;

  button {
    margin: vmin(10px) 0;
  }

  p {
    display: flex;
    text-align: left;
    margin: 0 auto;
  }

  h3 {
    padding-top: 0;
  }
}

.AnswerContainer {
  width: 100%;
  margin-bottom: auto;
  margin-top: vmin(30px);
  display: flex;
  flex-direction: column;
  gap: vmin(10px);
  overflow: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.Text {
  margin: auto;
}

