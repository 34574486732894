@import 'src/styles/variables';
@import 'src/styles/breakpoints';
@import 'src/styles/mixins';

.FullScreenVideo > div {
  object-fit: contain;
  user-select: text;
  position: fixed !important;
  box-sizing: border-box !important;
  min-width: 0 !important;
  max-width: none !important;
  min-height: 0 !important;
  max-height: none !important;
  width: 100% !important;
  height: 100% !important;
  transform: none !important;
  inset: 0 !important;
  margin: 0 !important;
}

.Media {
  display: flex;
  align-items: center;
  flex: 1;
  margin: auto auto vmin(20px);
  max-height: vmin(300px) !important;
  width: auto !important;
}

.Video {
  display: flex;
  align-items: center;
  flex: 1;
  margin: auto auto vmin(20px);
  max-height: vmin(300px) !important;

  width: vmin(640px) !important;
  height: vmin(360px) !important;

  & > div {
    width: 100%;
    margin: 0 auto;

    @include tablet {
      height: auto !important;
    }
  }
}