@import 'src/styles/variables';
@import 'src/styles/breakpoints';
@import "src/styles/mixins";

.Text {
  font-size: vmin(45px);
  flex: 1;
  display: flex;
  align-items: center;
  text-align: left;
}

.Media {
  display: flex;
  align-items: center;
  margin-bottom: vmin(20px);

  audio {
    height: revert !important;
  }
}

.Image {
  max-width: vmin(600px);
  max-height: vmin(400px);
  margin-bottom: vmin(20px);

  width: auto !important;
  height: 100%;

  @include tablet {
    max-height: initial !important;
    width: 100% !important;
    height: auto !important;
  }
}

.Bold {
  font-weight: bold;
}

.Answer {
  margin-bottom: vmin(100px);
}

.Button {
  margin-top: auto !important;
  display: flex;
  flex-direction: column;
  gap: 3vmin;
}

