@import 'src/styles/variables';
@import 'src/styles/breakpoints';
@import "src/styles/mixins";

.Timer {
  padding: 0 vmin(30px);
}

.ButtonJumpWrapper {
  position: absolute;
  right: vmin(40px);
  bottom: vmin(90px);

  text-align: center;

  @include breakpoint-max-width(1000px) {
    bottom: vmin(0px);
    left: vmin(50px);
    right: vmin(50px);
  }
}

.Container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  width: 100%;

  svg {
    z-index: 9;
  }
}

.Slide {
  overflow: auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  transition: all 0.2s ease-in-out;
  gap: vmin(20px);
  padding-bottom: vmin(60px);

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  p {
    display: flex;
    text-align: left;
    align-items: center;
    margin: auto;
  }

  h3 {
    padding: 0 !important;
  }
}

.IconArrow {
  height: vmin(20px) !important;
  color: #041A30;
}

.BackButton,
.ForwardButton {
  position: absolute !important;
  top: vmin(55px) !important;;
  right: vmin(40px) !important;

  width: auto !important;
  min-width: 0 !important;

  @include breakpoint-max-width(1200px) {
    top: vmin(70px) !important;
  }
}

.BackButton {
  right: auto !important;
  left: vmin(40px) !important;

  padding: vmin(12px) vmin(20px) vmin(12px) vmin(10px) !important;

  @include breakpoint-max-width(1200px) {
    padding-right: vmin(10px) !important;
  }
}

.ForwardButton {
  padding: vmin(12px) vmin(10px) vmin(12px) vmin(20px) !important;

  @include breakpoint-max-width(1200px) {
    padding-left: vmin(10px) !important;
  }
}

.DirectionButtonText {
  @include breakpoint-max-width(1200px) {
    display: none;
  }
}

.ArrowContainer {
  position: relative;
  width: 100%;
  height: 4%;
}

.Wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column-reverse;
}

.FooterWrapper {
  display: flex;
  flex-direction: column;
  //gap: 3vmin;
  position: absolute !important;
  bottom: vmin(30px);
  left: 0;
  right: 0;
}

.SavedAnswer {
  color: $color-accent;
}
