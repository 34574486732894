@import 'src/styles/variables';
@import 'src/styles/breakpoints';
@import 'src/styles/mixins';

.Text {
  font-size: vmin(45px);
  flex: 1;
  display: flex;
  align-items: center;
}

.Container {
  display: block;
  height: 100%;

  margin-top: vmin(-40px);
  padding-top: vmin(40px);

  h2, h3, h4 {
    margin-bottom: vmin(20px);
    padding-top: 0;
  }
}
