@import "variables";
@import "breakpoints";
@import 'mixins';

* {
  box-sizing: border-box;
}

html, body {
  font-size: vmin($typography-primary-font-size);
  position: relative;
  overflow: hidden;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

body {
  margin: 0;

  font-family: $typography-font-family;
  font-size: $typography-text-font-size;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

body::-webkit-scrollbar {
  display: none;
}

strong {
  font-weight: $typography-accent-font-weight;
}

h1, h2, h3, h4, h5 {
  margin: 0;
}

h1 {
  color: $color-white;
  font-size: vmin(48px);
  font-weight: 600;
  line-height: 1;
  margin-bottom: vmin(5px);
  padding: 0;
}

h2 {
  color: $color-white;
  font-size: vmin(32px);
  font-weight: 500;
  line-height: 1.25;
}

h3 {
  color: $color-white;
  font-size: vmin(28px);
  font-weight: 500;
}

h4 {
  color: $color-white;
  font-size: vmin(20px);
  font-weight: 500;
}

h5 {
  color: $color-white;
  font-size: vmin(14px);
  font-weight: 400;
  margin: 5vmin 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
  overflow-wrap: break-word;
  color: $color-white;
  font-size: $typography-text-font-size;
  font-weight: $typography-accent-font-weight;
  word-break: break-word;
}

table {
  svg {
    width: calc(2vmin + 1rem) !important;
    height: calc(2vmin + 1rem) !important;
  }
}

a {
  font-weight: $typography-text-font-weight;
  color: $color-white;
  fill: $color-white;
  font-size: 1.2rem !important;
  text-decoration: none;
}

ul {
  list-style: none;
  padding-left: 0;
}

li {
  color: $color-black-text;
  font-size: $typography-text-font-size;
  font-weight: $typography-text-font-weight;
  padding-bottom: vmin(5px);
  margin-left: vmin(30px);

  p {
    display: inline;
  }

  //&::before {
  //  content: "";
  //  background-color: $color-blue;
  //  display: inline-block;
  //  margin: vmin(2px) vmin(15px) vmin(2px) vmin(-30px);
  //  font-size: vmin(14px);
  //  width: vmin(14px);
  //  height: vmin(14px);
  //  border-radius: 50%;
  //}
}

input, select, textarea {
  font-family: $typography-font-family;
  font-size: $typography-text-font-size;
  font-weight: $typography-text-font-weight;
}

button {
  font-size: vmin(20px) !important;
  font-weight: $typography-primary-font-weight;
  font-family: $typography-font-family;
  background-color: $color-yellow;
  color: $color-deep-blue;
  border: none;
  border-radius: vmin(15px);
  padding: vmin(12px) vmin(39px);
  margin-bottom: vmin(4px) !important;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    color: $color-white;

    a {
      color: $color-blue;
    }
  }
}

button > a {
  font-weight: 300 !important;
}

textarea {
  resize: vertical;
}

#root {
  height: 100%;

  background: radial-gradient(#0e4577 0%, #0e4577 20%, #051B31 100%);
}

input {
  font-size: calc(1vmin + 1rem) !important;
}

.bg {
  &:before {
    content: '';

    position: fixed;
    top: 0;
    right: 0;

    width: 50vw;
    height: 50vh;

    background: url("../../public/images/bg-top-right.png") top right no-repeat;
    background-size: contain;

    pointer-events: none;

    z-index: 0;
  }
  &:after {
    content: '';

    position: fixed;
    bottom: 0;
    left: 0;

    width: 50vw;
    height: 50vh;

    background: url("../../public/images/bg-bottom-left.png") bottom left no-repeat;
    background-size: contain;

    pointer-events: none;

    z-index: 0;
  }
}
