@import 'src/styles/mixins';
@import 'src/styles/breakpoints';

.Logo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50%;
}

.Wrapper {
  position: relative;
  max-width: 60%;
  height: 100%;
  margin: auto;
  padding: vmin(50px);
  padding-top: 0;
  padding-bottom: vmin(10px);

  @include tablet {
    max-width: 100%;
  }
}

.Container {
  position: relative;

  padding-top: 40%;

  h2 {
    margin-bottom: vmin(80px);
  }
}
