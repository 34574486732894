@import 'src/styles/mixins';
@import 'src/styles/breakpoints';

.Table {}

.TableContainer {
  overflow: scroll;
  width: 100%;
  flex: 1;

  border: vmin(5px) solid #E2A41D;
  border-radius: vmin(16px);

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.TableCell {
  font-size: vmin(20px);
  font-weight: 600;
  color: #041A30;
  line-height: 1;
  text-align: center !important;
}

.TableCellHead {
  background-color: #041A30 !important;;

  color: #E2A41D !important;
}

.TableCellEdit {
  width: vmin(140px);
}

.NoneParticipants {
  flex: 1;
  display: flex;
  align-items: center;
}

.Button {
  margin-top: vmin(10px);
  margin-bottom: 5vmin !important;
}

.Grid {
  gap: 5vmin;
  overflow: scroll;
  height: 100%;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.TabGrid {
  padding-bottom: vmin(45px);
}

.AddItemCell {
  background-color: #041A30;

  color: #E2A41D;
}

.AddButtonWrapper {
  width: 100%;

  td {
    background-color: #041A30;

    color: #E2A41D;
    text-align: center !important;
  }
}

.IconWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.CellButtonWrapper {
  input {
    color: #fff !important;
  }
  fieldset {
    border-color: #fff !important;
    color: #fff !important;
  }
}
