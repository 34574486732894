@import 'src/styles/mixins';
@import 'src/styles/breakpoints';

.ParticipantContainer {
  border-bottom: 1px solid;

  p {
    margin: 0 !important;
  }
}

.ParticipantsContainer {
  height: vmin(300px);
  overflow-y: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.ListItemContainer {
  width: 100%;
  margin-top: vmin(30px);
  display: flex;
  flex-direction: column;
  gap: vmin(10px);
}

.Item {
  padding: vmin(10px);
}

.Team {
  display: flex;
}

.Text {
  margin: auto;
}

.ThemeContainer {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.Button {
  margin: auto auto vmin(15px) !important;
}

.Grid {
  padding-bottom: vmin(48px);
}
