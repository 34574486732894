@import 'src/styles/mixins';
@import 'src/styles/breakpoints';

.Box {
  border: 1px solid black;
  width: 100%;
  padding: vmin(10px);
}

.Title {
  margin-bottom: vmin(10px);
  border-bottom: 1px dashed black;

  p {
    font-weight: bold;
  }
}

.Content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  align-items: center;
  overflow: scroll;
  padding: vmin(20px) 0;
  gap: vmin(5px);

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  p {
    width: 100%;
  }
}

.Button {
  width: 70%;

  background-color: #E2A41D;

  &[disabled] {
    background-color: #E2B000 !important;

    box-shadow: 0 0 vmin(20px) 0 rgba(255, 241, 115, 0.60);
  }
}
